<template>
  <v-container
    id="message-history"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        md="3"
        offset-md="4"
        offset-lg="4"
      >
        <v-select
          v-model="selectedGroupId"
          :items="groups"
          item-text="name"
          item-value="id"
          label="Select a group"
          solo
          class="dropdown"
          @change="updateGroupSelection"
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-select
          v-model="selectedContact"
          :items="contacts"
          item-text="displayName"
          item-value="ContactID"
          label="Select a contact"
          solo
          class="dropdown"
          @change="updateContactSelection"
        />
      </v-col>
    </v-row>
    <!--  Show if message/group not selected  -->
    <v-row v-if="!isMessageDataAvailable">
      <v-col
        cols="12"
      >
        <div class="selectMessageInfo">
          Select a contact to get message histories
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isMessageDataAvailable">
      <v-col
        cols="12"
      >
        <div class="messageInfoWrapper">
          <div class="messageMetaData">
            <h3>Contact Name: {{ selectedContact.FirstName + " " + selectedContact.LastName }}</h3>
            <h3>CellNumber: {{ selectedContact.CellNumber }}</h3>
            <p class="statsData">
              Number of deliveries: {{ stats.delivered }}
            </p>
            <p>Failed deliveries: {{ stats.failed }}</p>
            <p>Undelivered messages: {{ stats.undelivered }}</p>
          </div>
          <div class="graphWrapper">
            <!-- <div id="chart" /> -->
            <div
              v-if="isPieChartVisible"
              class="pieChartWrapper"
            >
              <pie-chart :items="pieChartData" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isMessageDataAvailable">
      <v-col
        cols="12"
        md="3"
        offset-md="6"
        offset-lg="6"
        class="statusFilterWrapper"
      >
        <v-select
          v-model="selectedStatus"
          :items="status"
          item-text="name"
          item-value="id"
          label="Filter by status"
          solo
          class="dropdown"
          @change="filterByStatus"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="statusFilterWrapper"
      >
        <v-select
          v-model="selectedMessageType"
          :items="messageTypes"
          item-text="name"
          item-value="id"
          label="Filter by message type"
          solo
          class="dropdown"
          @change="updateMessageTypes"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="allMessages"
          item-key="id"
          sort-by="Status"
        >
          <template v-slot:no-data>
            <p class="mb-4 mt-4">
              No messages to display
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import moment from 'moment'
  import PieChart from '../../../components/PieChart'
  import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
  // import css
  import 'frappe-charts/dist/frappe-charts.min.css'

  export default {
    components: {
      PieChart,
    },
    props: {
      contactId: {
        required: false,
        type: Number,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      headers: [
        { text: 'Cell Number', value: 'CellNumber' },
        { text: 'Date Sent', value: 'dateSent' },
        { text: 'Message', value: 'Message', width: '50%' },
        { text: 'Group', value: 'GroupName' },
        { text: 'Sender', value: 'CellFrom' },
        { text: 'Status', value: 'status' },
        { text: 'Status Message', value: 'ErrorMessage' },
      ],
      snackbar: false,
      notification: 'Unknown',
      isOverlayVisible: false,
      selectedGroup: {
        id: -1,
        name: 'All Groups',
      },
      selectedGroupId: -1,
      selectedMessageType: '1',
      selectedMessage: null,
      selectedContact: null,
      selectedStatus: 'delivered',
      contacts: [],
      groups: [],
      messages: [],
      isMessageDataAvailable: false,
      sentMessages: [],
      failedMessages: [],
      deliveredMessages: [],
      undeliveredMessages: [],
      allMessages: [],
      status: [{
        name: 'Delivered',
        id: 'delivered',
      }, {
        name: 'Failed',
        id: 'failed',
      }, {
        name: 'Undelivered',
        id: 'undelivered',
      }],
      messageTypes: [{
        name: 'Message Sent',
        id: '1',
      }, {
        name: 'Responses',
        id: '2',
      }],
      pieChartData: [],
      isPieChartVisible: false,
      stats: null,
    }),

    async created () {
      this.usremail = localStorage.getItem('username')

      await this.initialize()
    },

    mounted () {},

    methods: {
      async initialize () {
        try {
          this.isOverlayVisible = true
          // First fetch all available groups
          const groupsResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}groups?code=${this.apiCode}`)
          this.groups = []
          this.groups.push({
            id: -1,
            name: 'All Groups',
          })
          groupsResponse.data.forEach((group) => {
            this.groups.push({
              id: group.groupID,
              name: group.name,
            })
          })
          // Next fetch all available contact
          await this.fetchContacts()
          await this.autoSelectContactAndGroupIfAvailable()
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      async fetchContacts () {
        if (this.selectedGroup.id === -1) {
          const contactsResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}ListContacts?code=${this.apiCode}`, {
            params: {
              UserID: this.usremail,
            },
          })
          this.contacts = contactsResponse.data.map(contact => {
            return {
              ...contact,
              displayName: `${contact.FirstName} ${contact.LastName} (${contact.CellNumber})`,
            }
          })
        } else {
          // Fetch all contacts of that group
          const contactsResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}group/${this.selectedGroup.id}/contacts?code=${this.apiCode}`)
          this.contacts = contactsResponse.data.map(contact => {
            return {
              ...contact,
              displayName: `${contact.FirstName} ${contact.LastName} (${contact.CellNumber})`,
            }
          })
        }
      },

      async autoSelectContactAndGroupIfAvailable () {
        const { contactId, groupId } = this.$route.query
        if (groupId && groupId.length) {
          // Check if group id is valid or not
          const group = this.groups.find(g => g.id === parseInt(groupId))
          if (group) {
            this.selectedGroup = group
            this.selectedGroupId = group.id
          }
        }
        // Fetch contacts of that group
        // Reset contact selections
        this.isOverlayVisible = true
        this.selectedContact = null
        await this.fetchContacts()
        this.isOverlayVisible = false
        if (!contactId && !contactId.length) {
          return
        }
        // Check if contact id is valid or not
        const contact = this.contacts.find(c => c.ContactID === parseInt(contactId))
        if (!contact) {
          return
        }
        this.selectedContact = contact
        await Promise.all([
          this.fetchMessagesOfSelectedContact(),
          this.fetchStats(),
        ])
        this.isMessageDataAvailable = true
        this.loadPieChart()
      },

      async fetchStats () {
        // Fetch stats data for selected contact
        const stats = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}contacthistorycnt/${this.selectedContact.CellNumber}/${this.selectedContact.GroupID}?code=${this.apiCode}`)
        const sent = stats.data.find(stat => stat.StatusMessage === 'sent')
        const delivered = stats.data.find(stat => stat.StatusMessage === 'delivered')
        const failed = stats.data.find(stat => stat.StatusMessage === 'failed')
        const undelivered = stats.data.find(stat => stat.StatusMessage === 'undelivered')
        this.stats = {
          sent: sent && sent.Count ? sent.Count : 0,
          delivered: delivered && delivered.Count ? delivered.Count : 0,
          failed: failed && failed.Count ? failed.Count : 0,
          undelivered: undelivered && undelivered.Count ? undelivered.Count : 0,
        }
      },

      async updateGroupSelection (selectedGroupId) {
        this.isMessageDataAvailable = false
        this.isOverlayVisible = true
        this.selectedGroupId = selectedGroupId
        this.selectedGroup = this.groups.find(g => g.id === selectedGroupId)
        // Reset contact selections
        this.selectedContact = null
        await this.fetchContacts()
        this.isOverlayVisible = false
      },

      async updateContactSelection (selectedContactId) {
        this.isMessageDataAvailable = false
        this.selectedContact = this.contacts.find(contact => contact.ContactID === selectedContactId)
        // Fetch all messages of selected contact
        this.isOverlayVisible = true
        await Promise.all([
          this.fetchMessagesOfSelectedContact(),
          this.fetchStats(),
        ])
        this.isOverlayVisible = false
        this.isMessageDataAvailable = true
        // this.loadLineGraph()
        this.loadPieChart()
      },

      async updateMessageTypes (newMessageType) {
        // Reset status selection
        this.selectedStatus = 'delivered'
        this.selectedMessageType = newMessageType
        await this.fetchMessagesOfSelectedContact()
      },

      async fetchMessagesOfSelectedContact () {
        this.isOverlayVisible = true
        try {
          const params = {
            CellNumber: this.selectedContact.CellNumber,
            MessageType: this.selectedMessageType,
          }
          if (this.selectedContact.GroupID) {
            params.GroupID = this.selectedContact.GroupID
          }
          const insightResponse = await Promise.all([
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'delivered',
            }),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'failed',
            }),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'undelivered',
            }),
          ])
          this.deliveredMessages = insightResponse[0].data.map(m => {
            return { ...m, status: 'delivered' }
          })
          this.failedMessages = insightResponse[1].data.map(m => {
            return { ...m, status: 'failed' }
          })
          this.undeliveredMessages = insightResponse[2].data.map(m => {
            return { ...m, status: 'undelivered' }
          })
          this.allMessages = this.formatMessages([
            ...this.deliveredMessages,
          ])
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      loadPieChart () {
        this.isPieChartVisible = false
        setTimeout(() => {
          // Prepare pe chart data
          this.pieChartData = [{
            label: 'Delivered',
            backgroundColor: '#00f07c',
            value: this.stats.delivered,
          }, {
            label: 'Failed',
            backgroundColor: '#eb4034',
            value: this.stats.failed,
          }, {
            label: 'Undelivered',
            backgroundColor: '#f56942',
            value: this.stats.undelivered,
          }]
          this.isPieChartVisible = true
        }, 200)
      },

      loadLineGraph () {
        setTimeout(() => {
          this.chart = new Chart('#chart', {
            title: 'Campaign Statistics ',
            data: this.chartData,
            type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
            height: 250,
            colors: ['#427ef5', '#00f07c', '#f00044'],
          })
        }, 1000)
      },

      async fetchAllMessagesOfSelectedGroup () {
        try {
          this.isOverlayVisible = true
          const messagesResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}Campaigns/${this.selectedGroup.id}?code=${this.apiCode}`)
          this.messages = messagesResponse.data.map((message, messageIndex) => {
            return {
              ...message,
              formatedName: `${message.campaignName} (${moment(message.dateSent).format('MM-DD-YYYY')})`,
              id: messageIndex,
              formattedDate: moment(message.dateSent).format('MM-DD-YYYY'),
            }
          })
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      formatMessages (messages) {
        return messages.map((message, index) => {
          return {
            ...message,
            id: index,
            dateSent: moment(message.CreateDate).format('MM-DD-YYYY'),
          }
        })
      },

      filterByStatus (status) {
        this.selectedStatus = status
        switch (status.toLowerCase()) {
          case 'delivered': {
            this.allMessages = this.formatMessages([
              ...this.deliveredMessages,
            ])
            break
          }
          case 'failed': {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
            ])
            break
          }
          case 'undelivered': {
            this.allMessages = this.formatMessages([
              ...this.undeliveredMessages,
            ])
            break
          }
          default: {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
              ...this.deliveredMessages,
            ])
          }
        }
      },
    },
  }

</script>

<style scoped lang="scss">
  .messageInfoWrapper {
    width: 100%;
    background-color: white;
    min-height: 100px;
    display: flex;
    @media only screen and (max-width: 968px) {
        display: block;
    }
    .messageMetaData {
      width: 50%;
      padding: 40px 20px 30px 60px;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      h3 {
        margin-bottom: 6px;
      }
      .dateDisplay {
        margin-bottom: 75px;
      }
      p {
        margin-bottom: 3px;
      }
    }
    .graphWrapper {
      width: 50%;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .pieChartWrapper {
        width: 280px;
      }
    }
  }
  .selectMessageInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 200px;
  }
    .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .statusFilterWrapper {
    margin-top: 25px;
    margin-bottom: -30px;
  }
  .orLabel {
    text-align: center;
    // align-items: center;
    padding-top: 25px;
  }
  .reset {
    color: red;
    cursor: pointer;
    text-align: center;
  }
  .statsData {
    margin-top: 5rem;
  }
</style>
